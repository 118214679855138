<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-auction-item-card">
        <div class="land-auction-item-content">
            <RouterLink :to="'/trade/gp/'+ isNull(landInfo.auctionInfo) ? '' : landInfo.auctionInfo.id " class="land-img">
                <img :src="image" class="card-image" />
                <div :class="'tag ' + 'processing'"
                     v-if=" landInfo.auctionInfo &&
                     (landInfo.transactionStatus=== 'processing' || landInfo.transactionStatus ==='finish'|| landInfo.transactionStatus ==='success')">
                {{ getTransactionStatus(landInfo.transactionStatus) }}
                </div>
            </RouterLink>
            <div class="card-content" style="padding: 0 14px">
                <h4><RouterLink :to="'/trade/gp/'+ isNull(landInfo.auctionInfo) ? '' : landInfo.auctionInfo.id ">{{ landInfo.title }}</RouterLink></h4>
                <div class="card-info-item" v-if="landInfo.auctionInfo">
                    <div>
                        <i class="ti ti-arrow-badge-up" style="font-size: 20px;position: relative;top:3px;"></i>
                        <span>资源编号：</span>
                        <span>{{ landInfo.id }}</span>
                    </div>
                    <div>
                        <i class="ti ti-map-pin"></i>
                        <span>土地位置：</span>
                        <span>{{ landInfo.landPosition }}</span>
                    </div>
                </div>
                <div class="card-info-item" style="height: 39px" v-else>
                <div>
                  <i class="" style="font-size: 20px;position: relative;top:3px;"></i>
                  <span style="font-size: 17px;position: relative;top:3px;">面积：</span>
                  <span style="font-size: 17px;position: relative;top:3px;">{{ landInfo.landSize }} 平方米</span>
                </div>
                <div>
                  <i class="" style="font-size: 20px;position: relative;top:3px;"></i>
                  <span style="font-size: 17px;position: relative;top:3px;">所属行政区：</span>
                  <span style="font-size: 17px;position: relative;top:3px;">{{ getXzqName(landInfo.regionId) }}</span>
                </div>
              </div>
                <div class="card-info-auction-time" v-if="landInfo.auctionInfo">
                    <div>
                        <div class="auction-time">{{ landInfo.auctionInfo.auctionStartTime | dateFormat }}</div>
                        <div class="auction-desc">挂牌开始时间</div>
                    </div>
                    <div class="arrow">
                        <i class="ti ti-arrow-right-rhombus"></i>
                    </div>
                    <div>
                        <div class="auction-time">{{ landInfo.auctionInfo.auctionEndTime | dateFormat }}</div>
                        <div class="auction-desc">挂牌结束时间</div>
                    </div>
                    <div>
                        <el-button type="primary" plain @click="toTrade">地块详情</el-button>
                    </div>
                </div>
                <div class="card-info-auction-time" v-else>
                  <div>
                    <span>土地用途：</span>
                    <span>
                          {{ getLandPurposeName(landInfo.landPurpose) }}
                    </span>
                  </div>
                  <div>
                    <el-button type="primary" plain @click="toDetail">地块详情</el-button>
                  </div>
                </div>
                <div class="card-info-deposit-time" v-if="landInfo.auctionInfo">
                    <el-tag type="success">报名截止日期</el-tag>&nbsp;
                    {{ landInfo.auctionInfo.applyEndTime | dateFormat}}
                </div>
                <div v-else>
                    <span>发布时间：</span>
                    {{ landInfo.createTime}}
                </div>
                <div class="card-info-item" v-if="landInfo.auctionInfo">
                    <div><i class="ti ti-coin-yen"></i> 起始价：{{ landInfo.auctionInfo.startPrice }}万元</div>
                    <div><i class="ti ti-coin-yen"></i> 保证金：{{ landInfo.auctionInfo.deposit }}万元</div>
                </div>
            </div>
        </div>
    </el-card>
</template>
<script>
import { RouterLink } from 'vue-router';
import dayjs from "dayjs";
import {isNull} from "eslint-plugin-vue/lib/utils/ts-utils/typescript";
import {useDict,findLabel} from "@/hooks/dict";
const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};

const land_purpose = {
    'residential': '住宅',
    'commercial': '商业',
    'industrial': '工业',
}

const transaction_status = {
    'processing': '正在交易',
    'success': '已成交',
    'fail': '未成交',
}

export default {
    name: "LandAuctionCard",
    data() {
        return {};
    },
    props: {
        landInfo: {
            type: Object,
            default: () => ({
                id: '',
                landPics: "",
                title: "name",
                landSize: 99.99,
                regionId: "武侯区",
                landPurpose: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
                auctionInfo:{
                  type: Object,
                  default:()  =>({
                    id: '',
                    auctionStartTime: "",
                    auctionEndTime: "",
                    depositEndTime: '',
                    startPrice: "",
                    deposit: "",
                  })
                }
            }),
        },
    },
    components: { RouterLink },
    computed: {
        image() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",")[0] +'?w=200': "";
        },
    },
  filters:{
    dateFormat:function (time) {
       return dayjs(new Date(parseInt(time))).format("YYYY-MM-DD HH:mm:ss");

    }
  },
    methods: {
      demand_purpose() {
        // console.log(this.getDict("demand_purpose"))
        return this.getDict("demand_purpose");
      },
      findLabel,
      getLandPurposeItemName(demand_purpose,id) {
        let land_purposes = useDict(demand_purpose);
        let tmp = '';
        land_purposes[demand_purpose].value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
      isNull,
        getXzqName(id) {
            return xzq[id];
        },
      getLandPurposeName(id) {
        let land_purposes = useDict('demand_purpose');
        let tmp = '';
        land_purposes.demand_purpose.value.forEach((item) => {
          if (item.value === id) {
            tmp =  item.label;
          }
        })
        return tmp;
      },
        getTransactionStatus(id){
            return transaction_status[id];
        },
        toDetail() {

          this.$router.push("/land/detail/"+this.landInfo.id);
        },
        toTrade() {
            this.$router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
        },
    },
};
</script>
<style lang="scss" scoped></style>
