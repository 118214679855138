<template>
    <div>
        <Nav  />
        <BreadCrumb/>
        <div class="center land-box">
            <GroupSelector/>
            <!-- <LandPanel title="协议交易" :tabs="tabs1" :activeTabName="activeTabName1" more-link="supply">
                <template v-slot:tab-ann>
                    <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="6" v-for="(o, index) in r" :key="index">
                            <LandItemCard :land-info="o" />
                        </el-col>
                    </el-row>
                </template>
                <template v-slot:tab-trading>
                    <el-empty :image-size="200"></el-empty>
                </template>
                <template v-slot:tab-deal>
                    <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="6" v-for="(o, index) in r" :key="index">
                            <LandItemCard :land-info="o" />
                        </el-col>
                    </el-row>
                </template>
            </LandPanel> -->
            <div style="margin-bottom: 50px;" v-if="!isListing && !isAuction">
                <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="6" v-for="(o, index) in r" :key="index">
                            <LandItemCard :land-info="o" />
                        </el-col>
                    </el-row>
              <div class="user-info-list-page">
                <el-pagination
                               background  style="float: right" layout="  prev, pager, next, jumper,total" :page-sizes="[16]" :page-count="datalist.totalPage" :current-page="datalist.current" @current-change="pageChange">
                </el-pagination>
              </div>
            </div>

            <div style="margin-bottom: 50px;" v-if="isListing">
                <el-row :gutter="20">
                        <el-col :span="24" v-for="(o, index) in group1Data" :key="index">
                            <LandAuctionCard :land-info="o" />
                        </el-col>
                    </el-row>


            </div>
          <div style="margin-bottom: 50px;" v-if="isAuction">
            <el-row :gutter="20">
              <el-col :span="24" v-for="(o, index) in group1Data" :key="index">
                <LandBargainCard :land-info="o" />
              </el-col>
            </el-row>


          </div>

            <el-empty description="没有相关交易数据" v-if="group1Data.length === 0"></el-empty>
<!--
            <LandPanel title="挂牌交易" :tabs="tabs2" :activeTabName="activeTabName2" more-link="supply">
                <template v-slot:tab-ann>
                    <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="6" v-for="(o, index) in r" :key="index">
                            <LandItemCard :land-info="o" />
                        </el-col>
                    </el-row>
                </template>
                <template v-slot:tab-trading>
                    <el-empty :image-size="200"></el-empty>
                </template>
                <template v-slot:tab-deal>
                    <el-row :gutter="20" v-for="(r, index) in group1DataNested" :key="index">
                        <el-col :span="6" v-for="(o, index) in r" :key="index">
                            <LandItemCard :land-info="o" />
                        </el-col>
                    </el-row>
                </template>
            </LandPanel> -->

          <div class="land-panel">
            <el-tabs v-model="activeTabName3" @tab-click="handleClick" :before-leave="moreState">
              <el-tab-pane name="title">
                <h2 slot="label">公告告示</h2>
              </el-tab-pane>
              <el-tab-pane label="默认隐藏tab" name="single-panel-default">
                <slot name="single-panel-default"></slot>
              </el-tab-pane>
              <el-tab-pane  label="交易公告" name="tab-notice-trnx">
                <el-table border :data="tableData" style="width: 100%" class="land-table">
                  <el-table-column show-overflow-tooltip  prop="title" label="资源名称"> </el-table-column>
                  <el-table-column show-overflow-tooltip  prop="announcementNo" label="公告编号"> </el-table-column>
                  <el-table-column prop="area" label="所属行政区"> </el-table-column>
                  <el-table-column  label="交易方式" width="120"> <template #default="scope">{{ findLabel(transaction_way,scope.row.transactionType) }} </template></el-table-column>
                  <el-table-column prop="createTime" label="日期" width="180"> </el-table-column>
                  <el-table-column prop="action" label="操作" width="180">
                    <template slot-scope="scope">
                      <RouterLink :to="'news-detail?id=' + scope.row.id">查看公告详情</RouterLink>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="user-info-list-page" style="margin-top: 20px">
                  <el-pagination background style="float: right" layout="  prev, pager, next, jumper,total" :page-sizes="[9]"  :page-count="newsDatalist.totalPage" :current-page="newsDatalist.current"  @current-change="newsPageChange">
                  </el-pagination>
                </div>
              </el-tab-pane>
              <el-tab-pane label="结果公示" name="tab-notice-result" >
                <el-table border :data="resultTableData" style="width: 100%" class="land-table">
                  <el-table-column show-overflow-tooltip prop="title" label="资源名称"> </el-table-column>
                  <el-table-column show-overflow-tooltip prop="announcementNo" label="公告编号"> </el-table-column>
                  <el-table-column prop="area" label="所属行政区"> </el-table-column>
                  <el-table-column label="交易方式" width="120">
                    <template #default="scope">{{ findLabel(transaction_way,scope.row.transactionType) }} </template></el-table-column>
                  <el-table-column prop="createTime" label="日期" width="180"> </el-table-column>
                  <el-table-column prop="action" label="操作" width="180">
                    <template slot-scope="scope">
                      <RouterLink :to="'news-detail?id=' + scope.row.id">查看公告详情</RouterLink>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="user-info-list-page mt10" style="margin-top: 20px">
                  <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :page-sizes="[9]"  :page-count="resultDatalist.totalPage" :current-page="resultDatalist.current" @current-change="resultPageChange">
                  </el-pagination>
                </div>
              </el-tab-pane>
<!--              <el-tab-pane label="结果公示" name="tab-notice-result" >-->
<!--                <slot name="tab-notice-result"></slot>-->
<!--              </el-tab-pane>-->
<!--              <template v-slot:tab-notice-result>-->
<!--                <el-empty :image-size="200"></el-empty>-->
<!--              </template>-->
            </el-tabs>
          </div>
        </div>
        <footer2 />
    </div>
</template>
<script>
import Nav from "@/components/nav";
import footer2 from "components/footer.vue";
import { RouterLink } from "vue-router";
import CommonService from "../../service/CommonService";
import GroupSelector from '@/components/GroupSelector'
import {useSearchStore} from '@/stores/search';
import LandAuctionCard from "../../components/LandAuctionCard.vue";
import LandBargainCard from "../../components/LandBargainCard.vue";
import { useDict,findLabel } from "@/hooks/dict";

export default {
    name: "TradingHallIndex",
    components: {
    Nav,
    footer2,
    RouterLink,
    GroupSelector,
    LandBargainCard,
    LandAuctionCard
},
    data() {
        return {
          datalist:{
            landSupplyType: 0,
            size:16,
            current:1,
            totalPage:1,
            },
          params: {
            keyword: "",
            land_type: "",
            land_area: "",
            transaction_way: "",
            demand_purpose: "",
          },
          newsDatalist:{
            size:9,
            current:1,
            totalPage:1,
          },
          resultDatalist:{
            size:9,
            current:1,
            totalPage:1,
          },
            searchStore:useSearchStore(),
            // tabs1: [
            //     // {
            //     //     name: "tab-ann",
            //     //     label: "公告中",
            //     // },
            //     // {
            //     //     name: "tab-trading",
            //     //     label: "交易中",
            //     // },
            //     {
            //         name: "tab-deal",
            //         label: "已成交",
            //     },
            // ],
            // activeTabName1: "tab-deal",
            // tabs2: [
            //     {
            //         name: "tab-ann",
            //         label: "公告中",
            //     },
            //     {
            //         name: "tab-trading",
            //         label: "交易中",
            //     },
            //     {
            //         name: "tab-deal",
            //         label: "已成交",
            //     },
            // ],
            activeTabName2: "tab-ann",
            tabs3: [
                {
                    name: "tab-notice-trnx",
                    label: "交易公告",
                },
                {
                    name: "tab-notice-result",
                    label: "结果公示",
                },
            ],
            activeTabName3: "tab-notice-trnx",

            radio: 1,
            mj: 4,
            qb: 1,
            currentDate: new Date(),
            group1Data: [],
            tableData: [
            ],
            resultTableData: [
            ],
        };
    },
    mounted() {
        this.init();
        this.searchStore.$subscribe((mutation, state) => {
            this.datalist.current = 1;
            this.search(state);
        })
    },
    computed: {
        transaction_way() {
          return this.getDict("transaction_way");
        },
        isListing(){
            return this.searchStore.params.transaction_way === 'listing';
        },
        isAuction(){
          return  this.searchStore.params.transaction_way === 'auction' ;
        },
        //array to nested array,4 items per row
        group1DataNested() {
            return this.group1Data.reduce((acc, cur, i) => {
                if (i % 4 === 0) {
                    acc.push([cur]);
                } else {
                    acc[acc.length - 1].push(cur);
                }
                return acc;
            }, []);
        },
    },
    methods: {
      findLabel,
      getDict(dictName) {
        return useDict(dictName)[dictName].value;
      },
      init() {

        if(this.$route.query.demand_purpose!=null && this.$route.query.demand_purpose!='')
        {
          this.searchStore.params.demand_purpose= this.$route.query.demand_purpose;
          this.params.demand_purpose=this.$route.query.demand_purpose;
          this.searchStore.$patch({
            ...this.params
          });
        }
          this.search(this.searchStore.params);
          this.getNewsList(1);
          this.getResultList(1);
      },
      getNewsListByType(page, type){
        let params_TradingHall= {
          current:page ?? 1,
          type:type ?? 6,
          size:9
        }
         CommonService.getNews(params_TradingHall).then((res) => {
           let number=res.data.total/this.newsDatalist.size;
           let totalPage ;
           if(number-parseInt(number)>0)
           {
             totalPage=parseInt(number)+1
           }else {
             if(number==0)
             {
               number++;
             }
             totalPage=parseInt(number);
           }
           if (type == 6) {
             this.tableData = res.data.records;
             this.newsDatalist.totalPage = totalPage;
           } else {
             this.resultTableData = res.data.records;
             this.resultDatalist.totalPage = totalPage;
           }
         });
      },
      getNewsList(page){
        this.newsDatalist.current = page;
         this.getNewsListByType(page, 6);
      },
      getResultList(page){
        this.resultDatalist.current = page;
       this.getNewsListByType(page, 7);
      },
      pageChange(page){
        this.datalist.current=page;
        this.search(this.searchStore.params);
      },
      newsPageChange(page){
        this.newsDatalist.current=page;
        this.getNewsList(page);
      },
      resultPageChange(page){
        this.resultTableData.current=page;
        this.getResultList(page);
      },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        moreState(tab, event) {
            if (tab == "more" || tab == "title") {
                // console.log("/////", tab, event);
                return false;
            }
        },
        search(selector = {}) {
        // console.log(selector);
            var params = {
                size:this.datalist.size,
                current:this.datalist.current,
                title:selector.keyword,
                regionId:selector.land_area,
                ownerType:selector.land_type,
                licenseNoType:selector.land_license_type,
                landPurpose:selector.demand_purpose,
                actualTransactionWay:selector.transaction_way,
                actualTransactionStatus:selector.transaction_status,
                landSize:selector.land_size,
            }
            CommonService.landSearch(params).then((res) => {
                if (res.code === 0) {
                  // console.log(res)
                    this.group1Data = res.data.records;
                  let numbe=res.data.total/this.datalist.size;
                  if(numbe-parseInt(numbe)>0)
                  {
                    this.datalist.totalPage=parseInt(numbe)+1
                  }else {
                    if(numbe==0)
                    {
                      numbe++;
                    }
                    this.datalist.totalPage=parseInt(numbe);
                  }

                }
            });


        }
    },
};
</script>
<style lang="scss" >
::v-deep .el-tabs {
    .el-tabs__content {
        .el-row {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.el-icon-arrow-right:before {
  content: '';//右边“下一页”三角箭头
}
.el-icon-arrow-left:before {
  content: '';//左边“上一页”三角剪头
}
 .el-pager li {
  min-width: 24px;//一定要加！不然默认的宽度是32px
  width: 24px;
  height: 28px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 4px;
  color: #626469; //字体颜色
  background: #ffffff; //背景色
  line-height: 28px;
  &.active { //选中时字体颜色、背景色变化
    color: #fb4a3e;
    background: #0960bd center center no-repeat;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0960bd;

}
</style>
